/* CSS for ContactUs Component */

.contact-section {
    background: rgba(0, 0, 0, 0.5); /* Adjust overlay opacity as needed */
    padding: 4rem 2rem;
    text-align: center;
    color: white;
  }
  
  .containerContact {
    max-width: 800px;
    margin: 0 auto;
  }
  
  .contact-form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  
  .form-group {
    display: flex;
    flex-direction: column;
    text-align: left;
  }
  
  .form-label {
    margin-bottom: 0.5rem;
    font-weight: bold;
  }
  
  .form-input {
    padding: 0.75rem;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: rgba(255, 255, 255, 0.1);
    color: white;
  }
  
  .form-input:focus {
    border-color: #1a73e8;
    outline: none;
  }
  
  .btn-primary {
    padding: 0.75rem 1.5rem;
    font-size: 1rem;
    cursor: pointer;
    border: none;
    border-radius: 5px;
    transition: background 0.3s;
    background-color: #1a73e8;
    color: white;
  }
  
  .btn-primary:hover {
    background-color: #1667c5;
  }
  