.carousel-container {
  display: flex;
  flex-direction: row;
  position: relative;
  width: 100%;
  height: 400px;
  overflow: hidden;
  margin: 0 auto;
  text-align: center;
}

.carousel {
  flex: 1;
  position: relative;
}

.carousel-card {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  transition: opacity 0.5s ease-in-out;
}

.carousel-card img {
  max-width: 70px;
  margin: 0 auto;
}

.carousel-card h2 {
  margin-top: 10px;
  font-size: 24px;
  color: white;
}

.fade-enter {
  opacity: 0;
}

.fade-enter-active {
  opacity: 1;
  transition: opacity 0.5s;
}

.fade-exit {
  opacity: 1;
}

.fade-exit-active {
  opacity: 0;
  transition: opacity 0.5s;
}
