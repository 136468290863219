/* CSS for Introduction Component */

.hero-section {
  position: relative;
  background-size: cover;
  background-position: center;
  color: white;
  animation: zoom 80s linear infinite;
}

.overlay {
  position: absolute;
  inset: 0;
  background: rgba(0, 0, 0, -0.1); /* Adjust or remove this line to change the overlay effect */
  z-index: 1;
}

.container {
  position: relative;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2rem;
}

.logo-container {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.text-container {
  flex: 2;
  max-width: 600px;
  text-align: left;
}

.content {
  text-align: left;
}

.buttons {
  display: flex;
  justify-content: start;
  gap: 1rem;
  margin-top: 1rem;
}

.btn-primary,
.btn-secondary {
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  cursor: pointer;
  border: none;
  border-radius: 5px;
  transition: background 0.3s;
}

.btn-primary {
  background-color: #1a73e8;
  color: white;
}

.btn-primary:hover {
  background-color: #1667c5;
}

.btn-secondary {
  background-color: transparent;
  border: 2px solid white;
  color: white;
}

.btn-secondary:hover {
  background-color: white;
  color: #1a73e8;
}

@keyframes zoom {
  0% {
    background-size: 100%;
  }
  100% {
    background-size: 170%;
  }
}

/* introduction.css */

.hero-section .overlay {
  background: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
}

.bio-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(10px);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 50;
  padding: 2rem;
  color: white;
}

.bio-content {
  background: rgba(30, 30, 30, 0.9);
  padding: 2rem;
  border-radius: 0.5rem;
  max-width: 600px;
  text-align: left;
  line-height: 1.6;
}

.btn-primary,
.btn-secondary {
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  cursor: pointer;
  border: none;
  border-radius: 5px;
  transition: background 0.3s;
}

.btn-primary {
  background-color: #1a73e8;
  color: white;
}

.btn-primary:hover {
  background-color: #1667c5;
}

.btn-secondary {
  background-color: transparent;
  border: 2px solid white;
  color: white;
}

.btn-secondary:hover {
  background-color: white;
  color: #1a73e8;
}

@keyframes zoom {
  0% {
    background-size: 100%;
  }
  100% {
    background-size: 170%;
  }
}
